// quantize returns the closest value from possibleValues to original that is greater than original.
// If original is greater than all possibleValues, the largest possibleValue is returned.
export const quantize = (original: number, possibleValues: number[]): number => {
  if (possibleValues.length === 0) {
    throw new Error('possibleValues must not be empty');
  }

  const sorted = possibleValues.sort((a, b) => a - b);

  const index = sorted.findIndex((value) => value >= original);

  if (index === -1) {
    return sorted[sorted.length - 1];
  }

  return sorted[index];
};
