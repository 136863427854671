import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReduxState } from 'ducks';

import { PopupMessage } from 'models/settings';

const toContentLanguage = (isoLang: string) => isoLang.toUpperCase().replace('-', '_');

export const usePopupMessage = (): PopupMessage | null => {
  const popupMessages = useSelector(
    (state: ReduxState) => state.server.settings.all.booking_widget_design_params?.popup_messages
  );

  const { i18n } = useTranslation();

  return (
    popupMessages?.find((popup) => popup.language === toContentLanguage(i18n.language)) || null
  );
};
